import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import { LocalBusinessJsonLd } from 'gatsby-plugin-next-seo';

import Header from "../components/header"
import Story from "../components/story"
import Uryslygi from "../components/uryslygi"
import Sdelka  from "../components/sdelka"
import Price from "../components/price"
import Catalog from "../components/catalog"

import OtzivAudio from "../components/otzivAudio"
import OtzivVideo from "../components/otzivVideo"
import OtzivSocial from "../components/otzivSocial"
import OtzivBlank from "../components/otzivBlank"
import OtzivMap from "../components/otzivMap"
import Social from "../components/social"
import Certificate from "../components/certificate"
import Users from "../components/users"

import Blog from "../components/blog"
import Text from "../components/text"
import Map from "../components/map"

import ContactForm from "../components/contactForm"
import Call from "../components/call"
import Sale from "../components/sale"
import CallForm from "../components/callForm"
import Stado from "../components/stado"
import Policy from "../components/policy"

export const query = graphql `
	query  {
		blogs: allBlog(sort: { order: DESC, fields: datePublished }, limit: 10) {
			edges {
			  node {
				slug
				title
				description
				card
				img
				imgMini
				date
				seoTitle
				seoDescription
				author
				ava
				time
			  }
			} 
		}
	}
`

const IndexPage = ({data}) => {
	return (
		<Layout>
			<Seo title="Исполнительное производство | Юридическая компания | Алмаз" description="! ⚖️ «Алмаз» ➥ ⭐ Консультирование по вопросам, связанным с исполнительным производством, разработка тактики ведения исполнительного производства, оценка перспектив" />

			<LocalBusinessJsonLd
				type='Store'
				id='https://юридические-услуги-казань.рф/ispolnitelnoe-proizvodstvo'
				name="Исполнительное производство | Юридическая компания | Алмаз"
				description="! ⚖️ «Алмаз» ➥ ⭐ Консультирование по вопросам, связанным с исполнительным производством, разработка тактики ведения исполнительного производства, оценка перспектив"
				url='https://юридические-услуги-казань.рф'
				telephone='+78005519518'
				address={{
					streetAddress: 'Щапова, 26',
					addressLocality: 'Казань',
					addressRegion: 'Татарстан',
					postalCode: '420012',
					addressCountry: 'RU',
				}}
				geo={{
					latitude: '55.791461',
					longitude: '49.136606',
				}}
				images={[
					'https://юридические-услуги-казань.рф/img/logo.png'
				]}
			/>

			<Header title="Исполнительное производство" card="Сомневаетесь, что хороший юрист Вам по карману? Не знаете, как подготовиться к судебному процессу и защитить свои права? Запутались в законах и не знаете, как грамотно сформулировать претензию ?" />
			
			<Story />
			<Uryslygi />
			<Sdelka />
			<Price />
			<Catalog />
			<OtzivAudio />
			<OtzivVideo />
			<OtzivSocial />
			<OtzivBlank />
			<OtzivMap />
			
			<Social />
			<Certificate />
			<Users />

			<Blog data={data.blogs} />

			<Text 
				title={"Исполнительное производство"}
			
				html={

				`
				<p class="TextText">
					Исполнительное производство - это установленный законом порядок принудительного исполнения постановлений судов.
				</p>

				<p class="TextText">
					Исполнительное производство возбуждается службой судебных приставов на основании исполнительного листа, выдаваемого судом после вступления решения в законную силу.
				</p>

				<p class="TextText">
					Для должника возбуждение исполнительного производства сопряжено с неблагоприятными последствиями, в том числе блокированием расчетного счета, арестом и последующей продажей судебными приставами основных активов предприятия по сомнительно низкой цене.
				</p>

				<p class="TextText">
					Своевременная реализация предусмотренного законом комплекса мер, позволяет должнику избежать наступления указанных неблагоприятных последствий, получить отсрочку необходимую для аккумуляции финансов и минимизировать затраты для расчетов с кредиторами. Как правило, двухмесячный срок, установленный законом на исполнение судебного решения, на практике увеличивается в несколько раз, а в отдельных случаях достигает нескольких лет.
				</p>

				<p class="TextText">
					<b>Мы оказываем следующие юридические услуги:</b>
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Консультирование по вопросам, связанным с исполнительным производством, разработка тактики ведения исполнительного производства, оценка перспектив
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Осуществление деятельности по исполнению вступивших в законную силу судебных актов, в том числе по взысканию денежных средств, возврату имущества, наложению ареста на имущество и др
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Представление интересов физических и юридических лиц в исполнительном производстве не зависимо от их процессуального положения
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Подготовка необходимых документов (постановлений, запросов, заявлений, сопроводительных писем) для исполнения решения;
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Представление исполнительных документов к исполнению
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Ознакомление с материалами исполнительного производства
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Присутствие при выполнении действий судебным приставом-исполнителем; Правовая оценка действий судебных приставов
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Обжалование действий (бездействий) судебного пристава-исполнителя
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Взыскание ущерба, причиненного действиями судебных приставов
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Защита прав лиц, не являющихся сторонами исполнительного производства
				</p>

				`

			}/>

			<Map />

			<ContactForm />
			<Sale />
			<Call />
			<CallForm />
			<Stado />
			<Policy />
		</Layout>
	)
}


export default IndexPage
